var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "customer" },
    [
      _c("div", { ref: "contentTop" }, [
        _c("div", { staticClass: "tool" }, [
          _c("div", { staticClass: "customer-tool" }, [
            _c("div", [
              _c(
                "span",
                { staticClass: "tab-bar clearfix" },
                _vm._l(_vm.tabList, function(item, index) {
                  return _c(
                    "span",
                    {
                      key: index,
                      class: { active: item.path === _vm.activeName },
                      on: {
                        click: function($event) {
                          return _vm.handleClick(item.path)
                        }
                      }
                    },
                    [_vm._v(_vm._s(item.name))]
                  )
                }),
                0
              )
            ]),
            _c(
              "div",
              { staticStyle: { "line-height": "40px" } },
              [
                _vm.permUtil.WbOrder.orderAfterTurnClassPassedCancel()
                  ? _c(
                      "el-button",
                      {
                        staticClass: "hq-button",
                        attrs: {
                          disabled: _vm.currCol.afterSaleType != 2,
                          type: "danger"
                        },
                        on: {
                          click: function($event) {
                            _vm.visibleShow = true
                          }
                        }
                      },
                      [_vm._v("转班撤销")]
                    )
                  : _vm._e(),
                _vm.permUtil.WbOrder.orderAfterRefundCancel()
                  ? _c(
                      "el-button",
                      {
                        staticClass: "hq-button",
                        attrs: {
                          disabled: !(
                            _vm.currCol.aftersaleTypeName == "退费" &&
                            _vm.currCol.status == 4
                          ),
                          type: "danger"
                        },
                        on: {
                          click: function($event) {
                            _vm.visibleShow = true
                          }
                        }
                      },
                      [_vm._v("退费撤销")]
                    )
                  : _vm._e(),
                _vm.permUtil.WbOrder.orderAfterExport()
                  ? _c("Export", {
                      attrs: {
                        pageParam: _vm.pageParam,
                        total: _vm.total,
                        searchParam: _vm.searchParam
                      }
                    })
                  : _vm._e()
              ],
              1
            )
          ])
        ]),
        _c(
          "div",
          {
            staticStyle: { width: "94%", margin: "0 auto" },
            attrs: { gutter: 10 }
          },
          [
            _vm.searchParamList.length
              ? _c("Search", {
                  attrs: {
                    searchAllParam: _vm.searchAllParam,
                    searchParamList: _vm.searchParamList,
                    searchParamFromApi: _vm.searchParamFromApi,
                    code: "afterSaleList"
                  },
                  on: {
                    search: _vm.search,
                    reset: _vm.reset,
                    searchMore: _vm.searchMore,
                    searchList: _vm.searchList
                  }
                })
              : _vm._e()
          ],
          1
        ),
        _c("div", { staticClass: "line" })
      ]),
      _c(
        "el-row",
        { staticStyle: { width: "94%", margin: "0 auto" } },
        [
          _c(
            "el-col",
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.listLoading,
                      expression: "listLoading"
                    }
                  ],
                  ref: "multipleTable",
                  staticStyle: { width: "100%" },
                  attrs: {
                    "highlight-current-row": "",
                    data: _vm.tableData,
                    "tooltip-effect": "dark",
                    border: "",
                    stripe: "",
                    height: _vm.tableHeight,
                    size: "mini",
                    "element-loading-text": "拼命加载中",
                    "row-style": { height: "20px" },
                    "cell-style": { padding: "0px" },
                    "header-cell-style": { background: "#e5f2ff" }
                  },
                  on: { "row-click": _vm.clickRow }
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      "show-overflow-tooltip": true,
                      label: "学员姓名",
                      prop: "nickName",
                      width: "120"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      "show-overflow-tooltip": true,
                      label: "原报名单号",
                      prop: "orderNo",
                      width: "150"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      "show-overflow-tooltip": true,
                      label: "原报名商品",
                      prop: "goodsAddSpecName",
                      width: "200"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      "show-overflow-tooltip": true,
                      label: "售后单号",
                      prop: "afterSaleNo",
                      width: "160"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      "show-overflow-tooltip": true,
                      label: "售后报名商品",
                      prop: "goodsAddSpecOneAddTwo",
                      width: "200"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      "show-overflow-tooltip": true,
                      label: "售后类型",
                      prop: "aftersaleTypeName",
                      width: "90"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      "show-overflow-tooltip": true,
                      label: "售后校区",
                      prop: "schoolName",
                      width: "140"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      "show-overflow-tooltip": true,
                      label: "售后状态",
                      prop: "statusNmae",
                      width: "100"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      "show-overflow-tooltip": true,
                      label: "钉钉单号",
                      prop: "processInstanceCode",
                      width: "200"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      "show-overflow-tooltip": true,
                      label: "申请人",
                      prop: "creater",
                      width: "120"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      "show-overflow-tooltip": true,
                      label: "申请时间",
                      width: "150",
                      prop: "createTime"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      "show-overflow-tooltip": true,
                      label: "完成时间",
                      prop: "finishTime",
                      width: "150"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "操作",
                      width: "220",
                      prop: "",
                      fixed: "right"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            scope.row.afterSaleType != 3
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text" },
                                    on: {
                                      click: function($event) {
                                        return _vm.goDetail(scope.row)
                                      }
                                    }
                                  },
                                  [_vm._v("详情")]
                                )
                              : _vm._e(),
                            scope.row.status == 0 &&
                            scope.row.afterSaleType != 3
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text" },
                                    on: {
                                      click: function($event) {
                                        return _vm.deleteOrder(scope.row)
                                      }
                                    }
                                  },
                                  [_vm._v("删除")]
                                )
                              : _vm._e(),
                            scope.row.status !== 0 &&
                            scope.row.aftersaleTypeName != "升班"
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text" },
                                    on: {
                                      click: function($event) {
                                        return _vm.showReviewHistory(scope.row)
                                      }
                                    }
                                  },
                                  [_vm._v("审核记录")]
                                )
                              : _vm._e(),
                            (scope.row.afterSaleType != 3
                            ? scope.row.afterSaleType == 1
                              ? scope.row.status != 0 && scope.row.status != 6
                              : scope.row.status === 1
                            : false)
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text" },
                                    on: {
                                      click: function($event) {
                                        return _vm.repeal(scope.row)
                                      }
                                    }
                                  },
                                  [_vm._v("撤销")]
                                )
                              : _vm._e()
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "page_test_bottom" },
                [
                  _c("el-pagination", {
                    attrs: {
                      background: "",
                      layout: "total, sizes, prev, pager, next, jumper",
                      "current-page": _vm.pageParam.pageNum,
                      "page-sizes": [10, 20, 50, 100],
                      "page-size": _vm.pageParam.pageSize,
                      total: _vm.total
                    },
                    on: {
                      "size-change": _vm.sizeChange,
                      "current-change": _vm.currentChange
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("review-history", {
        ref: "drawer",
        attrs: {
          visible: _vm.onReviewHistoryStatus,
          reviewData: _vm.reviewData
        },
        on: { "on-cancel": _vm.onReviewHistoryCancel }
      }),
      _c(
        "HqModal",
        {
          attrs: {
            visible: _vm.visibleShow,
            title: _vm.currCol.afterSaleType == 2 ? "转班" : "退费撤销",
            width: "400"
          },
          on: {
            "on-cancel": function($event) {
              _vm.visibleShow = false
            }
          }
        },
        [
          _c(
            "el-form",
            { ref: "form", attrs: { "label-width": "100px" } },
            [
              _c(
                "el-row",
                { attrs: { gutter: 10 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c("el-form-item", { attrs: { label: "售后单号" } }, [
                        _c("span", [_vm._v(_vm._s(_vm.currCol.afterSaleNo))])
                      ])
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c("el-form-item", { attrs: { label: "原订单" } }, [
                        _c("span", [_vm._v(_vm._s(_vm.currCol.orderNo))])
                      ])
                    ],
                    1
                  ),
                  _c("br"),
                  _vm.currCol.afterSaleType != 2
                    ? [
                        _c(
                          "el-col",
                          { attrs: { span: 24 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  prop: "recruitChannelsId",
                                  label: "是否贷款退费"
                                }
                              },
                              [_c("span", [_vm._v(_vm._s(_vm.currCol.isLoan))])]
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 24 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  prop: "recruitChannelsId",
                                  label: "付款方式"
                                }
                              },
                              [
                                _c("span", [
                                  _vm._v(_vm._s(_vm.currCol.payTypeName))
                                ])
                              ]
                            )
                          ],
                          1
                        )
                      ]
                    : _vm._e()
                ],
                2
              )
            ],
            1
          ),
          _vm.currCol.afterSaleType == 2
            ? [
                _c(
                  "div",
                  {
                    staticStyle: {
                      fontSize: "12px",
                      fontWeight: "bold",
                      "margin-bottom": "12px"
                    }
                  },
                  [
                    _vm._v(
                      " 使用场景：校区在转班审批通过后，发现转班申请有问题，或学员后悔，或招生老师填写转班金额有误，或订单金额有误解等，需重新申请！ "
                    )
                  ]
                ),
                _c("div", { staticStyle: { fontSize: "12px", color: "red" } }, [
                  _vm._v(" 操作指引： "),
                  _c("br"),
                  _vm._v(
                    " 1、检查NC 智慧校区售后单的付款单的勾对状态，如果已勾对，先看付款方式，如果是银行、贷款类付款，则要检查资金部是否有打款，如果有打款则不能撤销，没有打款可以撤销；如果是余额，则可以撤销 "
                  ),
                  _c("br"),
                  _vm._v(
                    " 2、检查转班的订单是否已经收款，如果已经存在收款，则不允许撤销 "
                  )
                ])
              ]
            : [
                _c(
                  "div",
                  {
                    staticStyle: {
                      fontSize: "12px",
                      fontWeight: "bold",
                      "margin-bottom": "12px"
                    }
                  },
                  [
                    _vm._v(
                      " 使用场景：校区在退费/转班审批通过后，发现退费/转班申请有问题，或学员后悔，或招生老师填写退费金额有误，或退款审批通过，资金部没打款，需要撤回退费、重新申请！ "
                    )
                  ]
                ),
                _c("div", { staticStyle: { fontSize: "12px", color: "red" } }, [
                  _vm._v(
                    " 操作指引：检查NC 智慧校区付款单勾对状态，如果已勾对，先看付款方式，如果是银行、贷款类付款，则要检查资金部是否有打款，如果有打款则不能撤销，没有打款可以撤销；如果是余额，且余额还没有被使用，则可以撤销 "
                  )
                ])
              ],
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.visibleShow = false
                    }
                  }
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.saveClick } },
                [_vm._v("确认")]
              )
            ],
            1
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }